/*
  COMBOBOX STYLES
*/

$dmp-combobox: '#{$dmp}-combobox';

/* Common styles for both types of comboboxes */

.#{$dmp-combobox} {
  position: relative;

  // Overwrites
  .rw-combobox .rw-widget-picker {
    border: 1px solid $gray-3;
    grid-template: 1fr/1fr 2.5em;
  }

  .rw-combobox .rw-widget-picker:hover {
    background-color: $gray-4;
    border: 1px $gray-2 solid;
  }

  .rw-widget-picker:active {
    background-color: $white;
    border: 1px $gray-2 solid;
  }

  .rw-widget-input {
    box-shadow: none;
    // transition: background-color 0.2s ease-in-out;
  }

  .rw-combobox.rw-state-focus {
    &,
    &:hover {
      .rw-widget-picker {
        background-color: $white;
      }
    }
  }

  .rw-picker-btn:hover {
    background-color: $gray-4;
  }

  .rw-list-option.rw-state-selected {
    background-color: $gray-4;
    border-color: $gray-4;
    color: $gray-0;
  }

  .rw-popup:not(:has(.rw-list-option)) {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-2;
  }

  .rw-state-focus .rw-widget-picker {
    border: $gray-2 1px solid;
    box-shadow: 0 0 0 0;
  }

  .rw-input-addon.rw-picker-btn.rw-btn {
    border-left: none;
  }

  cursor: pointer;

  .rw-combobox-input .rw-widget-input .rw-input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .rw-popup .rw-list {
    max-height: 385px;
  }

  .rw-list-empty {
    color: $gray-2;
  }

  .dropdown-closed .rw-input:hover {
    cursor: pointer;
  }

  .dropdown-open .rw-input:hover {
    cursor: text;
  }

  &.clearable {
    .rw-input {
      padding-right: 3rem;
    }
  }

  // Clear Button
  .clear-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 3.75rem;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1;
    border-right: 1px solid $gray-3;

    svg {
      fill: $gray-1;
    }
  }

  .rw-widget-picker {
    grid-template: 1fr/1fr 3.5em;
  }

  /* Disabled state styles */
  .rw-state-disabled .rw-widget-input {
    background-color: $gray-5;
    color: $gray-2;
    cursor: not-allowed !important;
    .rw-picker-btn svg {
      fill: $gray-2;
    }
    .rw-combobox {
      background-color: $gray-5;
    }
  }

  .rw-picker-btn:disabled {
    background-color: $gray-5;
  }

  /* Size styles */
  &.#{$dmp-combobox}-size-small {
    .rw-widget-picker,
    .rw-widget-container {
      min-height: 30px;
      font-size: 12px;
    }

    .rw-widget-picker input {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 18px;
    }

    .rw-input,
    .rw-list-option {
      font-size: 12px;
    }
    .clear-button {
      right: 30px;
    }
  }

  &.#{$dmp-combobox}-size-medium {
    .rw-widget-picker,
    .rw-widget-container {
      min-height: 40px;
    }
    .clear-button {
      right: 35px;
    }

    .rw-widget-picker input {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 24px;
    }
  }

  &.#{$dmp-combobox}-size-large {
    .rw-widget-picker,
    .rw-widget-container {
      min-height: 55px;
    }
    .clear-button {
      right: 40px;
    }

    .rw-widget-picker input {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 30px;
    }
  }

  &-block {
    display: block;
    flex: 1;
    > .btn {
      float: initial;
    }
  }

  /*
    New Item Styles
  */
  .item {
    padding: 7.5px 12px;
  }

  .new-item {
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  .new-item .dropdown-icon {
    margin-right: 10px;
  }

  .no-option-item {
    border-bottom: 1px solid $gray-3;
  }

  /*
    Dropdown combobox
  */
  &.#{$dmp-combobox}-type-dropdown {
    background-color: $white;
    border-radius: 3px;

    .rw-open {
      background-color: $white;
      border-color: $gray-2;
    }

    .rw-input {
      padding-left: 12px;
    }

    .rw-list-option {
      font-size: 14px;
      padding: 0;
    }
  }

  /*
    Search combobox
  */
  &.#{$dmp-combobox}-type-search {
    background-color: $white;
    border-radius: 0;
    border-left: none;
    border-right: none;

    .rw-combobox .rw-widget-picker {
      border-radius: 0;
      border-left: none;
      border-right: none;
    }

    .rw-input {
      padding-left: 20px;
    }

    .rw-list {
      padding-top: 0;
      padding-bottom: 0;
    }

    .rw-list-option {
      font-size: 14px;
      padding: 7.5px 14px 7.5px 18px;
      border-bottom: 1px solid $gray-3;
      border-radius: 0;
      margin: 0;
    }

    .rw-popup {
      margin-top: 0;
      border-radius: 0;
    }

    .clear-button {
      right: 15px;
    }

    /* Search option container styles */
    .search-option-container {
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }

    .search-option-content {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .search-option-title {
      font-weight: 600;
    }

    .search-option-description {
      display: block;
      color: $gray;
      font-weight: 400;
    }

    .search-option-chevron {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .search-option-chevron svg {
      fill: $gray-3;
    }
  }
}
