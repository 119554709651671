.template-variable {
  //used on .cloud and .template-editor
  cursor: pointer;
  color: $black70;
  display: inline;
  position: relative;
  .mask {
    //this needs to be in here so help me
    display: none;
  }
  // Put images on their own line
  &.has-image {
    display: block;
    width: auto;
    padding: 2px;

    img {
      max-width: 100%;
    }
  }

  &.SIMPLE {
    @include element($entity-var-color);
  }
  &.PARTY {
    @include element($entity-party-color);
  }
  &.PROTECTED {
    @include element($entity-protected-color);
  }
  &.CONNECTED {
    @include element($entity-connected-color);
  }
  &.CALCULATED {
    @include element($black100);
  }
  &.FOOTNOTE {
    @include element($entity-candidate-color);
  }
  &.PAGE_COUNT {
    pointer-events: none;
    color: $gray-2;
    background: $gray-4;
    &:after {
      background: $gray-5;
    }
  }

  &.FIELD {
    color: $black100;
    background: $black10;
  }

  &.REF {
    //slightly different
    border-radius: 0;
    padding: 0;
    border: 0; //remove default styles used by .simple + .party
    display: inline-block;
    position: relative;
    cursor: pointer;
    text-indent: 0;

    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 3px;
      height: 2px;
      border-radius: 5px;
      background: rgba($entity-ref-color, 0.3);
      width: 100%;
    }
    &:hover {
      &:before {
        background: rgba($entity-ref-color, 0.5);
      }
    }
  }
  &.used {
    background: $black2;
    color: $black60;
    border-color: rgba($black40, 0.25);
    &:hover {
      color: $black70;
      border-color: rgba($black40, 0.4);
    }
  }
  &.broken {
    @include element($alert);
  }
  &.disabled {
    pointer-events: none;
    color: $gray-2;
    background: $gray-4;
    border: 1px solid $gray-3;
    cursor: not-allowed !important;
    &:after {
      background: $gray-5;
      cursor: not-allowed !important;
    }
  }

  .resolve {
    height: 8px;
    width: 8px;
    border-radius: 100%;
    border: 1px solid #fff;
    background: #f6b36b;
    position: absolute;
    left: -4px;
    top: -4px;
  }

  &.linked {
    pointer-events: none;
    color: $gray-2;
    background: $gray-4;
    cursor: not-allowed !important;
    &:after {
      background: $gray-5;
      cursor: not-allowed !important;
    }
    &.loading {
      border-bottom: 1px dashed $gray-2 !important;
    }
    &.CONNECTED {
      border-bottom: 1px dashed $entity-connected-color;
    }
    &.broken {
      border-bottom: 1px dashed $alert;
    }
  }
}

.preview-modal {
  @include mediaquery-medium {
    max-width: map-get($grid-breakpoints, 'md');
  }

  @include mediaquery-large {
    max-width: $legal-paper-width; //To match .outer-paper-layout //Previously: $page-large;
  }

  .modal-header {
    border-bottom: none !important;
    text-align: center !important; //center buttons if they're there
    min-height: 40px; //ensure some top margin if the mode buttons aren't rendered (due to no overview content)
  }
  .modal-content {
    background: none;
    margin: 0 auto;
    .mode-toggle {
      margin: 20px 0;
    }
    .modal-body {
      margin: 0 auto; //center preview content
      min-height: 400px; //ensure some space while preview is loading
    }
  }

  .pop-link {
    margin-top: -6px;
  }
}

.ai-thread-view {
  position: fixed;
  left: -400px; // Start in collapsed state
  top: 0;
  height: 100%;
  width: 400px; // Fixed width
  transition: left 0.3s ease; // Smooth transition for sliding in and out

  &.expanded {
    left: 0; // Expanded state
  }

  .thread-messages {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    color: #abb2bf;
    padding: 10px;
    overflow: auto;
    transition: padding 0.3s ease; // Transition for padding change
  }

  .dmp-switch {
    span {
      color: #fff;
    }
  }

  .message {
    margin-bottom: 16px;
    border-left: 3px solid #abb2bf; // Default border color
    padding-left: 10px;

    &-role {
      font-size: 12px; // Larger font size for role and timestamp
      margin-bottom: 4px;

      span:first-child {
        font-weight: bold; // Role label bold
      }

      .timestamp {
        color: #abb2bf; // Timestamp color
      }
    }

    &-content {
      white-space: pre-wrap;
      font-size: 11px; // Smaller font size for message content
    }

    &.user-message {
      border-left-color: #61afef; // User message border color
    }

    &.assistant-message {
      border-left-color: #c678dd; // Assistant (Vinnie) message border color
    }
  }

  .message-end {
    float: left;
    clear: both;
  }
}

.template-editor {
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;

  //status message to show saving indicator
  .status-message {
    z-index: 5000;
    position: fixed;
    top: 3px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    height: 24px;

    @keyframes hide-message {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }

    span {
      background-color: $gray-3;
      color: $gray-0;
      padding: 3px 8px;
      display: inline-block;
      text-align: center;
      opacity: 1;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      display: flex;
      align-items: center;

      img,
      svg {
        height: 18px;
        margin-right: 5px;
      }

      .loader {
        margin-right: 5px;
      }

      &.info {
        background: $color-blue;
        color: $white;
      }

      &.error {
        background-color: $color-red;
        color: $white;
      }

      &.connect-success {
        background-color: $entity-connected-color;
        color: $white;
        svg {
          path {
            fill: white;
          }
        }
      }

      &.hide-message {
        animation: hide-message 1s forwards;
        animation-delay: 3s;
      }
    }
  }

  .editor-container {
    position: fixed;
    top: $template-toolbar-height + $top-bar-height-slim;
    bottom: 0;
    width: 100%;
    display: flex;

    .editor-content {
      position: relative;
      flex: 1;
      padding-right: $panel-desktop-width;
      background: $black3;

      // Comments in Draft are "contained" by the activity anchor div, in order to make scrolling work properly
      // So we need to pull it left by half the width to get it to show beneath the anchor
      .popover-activity-view {
        margin-left: -105px;
      }
    }

    .source-wrapper {
      max-height: 50%;
      overflow: scroll;
      overflow-x: hidden;
      &.solo {
        max-height: 100%;
      }
    }

    .summary-wrapper {
      max-height: 50%;
      overflow: scroll;
      overflow-x: hidden;
      border-top: 3px solid $black20;
      position: relative;
    }

    .overview-panel-header {
      display: flex;
      padding: 0px 10px;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      height: 40px;
      width: 99%;
      background: rgb(255, 255, 255);
      border-bottom: 1px solid rgb(229, 229, 229);
      border: 1px solid rgb(229, 229, 229);

      // .panel-title {
      //   flex: 1;
      //   font-weight: 600;
      //   font-size: 1.2rem;
      // }
      .panel-close {
        color: $black20;
        cursor: pointer;
        font-weight: 700;
        &:hover {
          color: $black60;
        }
      }
    }

    .overview-label {
      position: absolute;
      top: calc(50% - 19px);
      left: calc(50% - 38px - #{$panel-desktop-width}/ 2);
      background: $black80;
      color: $white;
      font-size: 1.1rem;
      font-weight: 600;
      padding: 2px 8px;
      width: 76px;
      text-align: center;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }

    .summary-page,
    .source-page {
      @extend .papershadow;
      max-width: $legal-paper-width;
      min-width: $legal-paper-width;
      min-height: $legal-paper-height;
      background: $white;
      margin: 50px auto 300px;
      position: relative;
    }

    .source-page {
      @extend .font_serif_app;

      .template-header {
        text-align: left;
        &:hover {
          background: $black2;
          cursor: pointer;
        }
        .deal-branding {
          padding-bottom: 0;
        }
      }
    }

    .footnotes {
      margin-bottom: 16px;

      .footnote-content {
        display: flex;
        flex-direction: row;
        flex: 1;

        &.numbered {
          margin-left: 32px;
        }

        .footnote-order {
          vertical-align: super; /* 2 */
          font-size: 10px;
          margin-right: 5px;
        }
        .footnote-body {
          text-align: left;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }

    // Using DealPanel for consistency so some overrides are required
    .editor-sidebar {
      bottom: 0;
      top: -2px !important;
      border-radius: 0;
      box-shadow: none;
      border-left: 1px solid $black10;

      // The > is necessary to ensure we don't accidentally also target sub popovers (like VariableEditor)
      > .popover-content {
        border: none;
        min-height: calc(100vh - #{$top-bar-height-slim} - #{$template-toolbar-height});

        > .panel-header {
          padding: 0;
          border-bottom: none;
        }

        > .panel-body {
          display: flex;
          flex-direction: column;
          flex-grow: 1;

          .panel-scroll {
            flex-grow: 1;
            overflow-y: auto;
            /* Important: https://medium.com/@stephenbunch/how-to-make-a-scrollable-container-with-dynamic-height-using-flexbox-5914a26ae336 */
            min-height: 0;
          }
        }
      }

      .empty-state {
        flex: 1;
        background: none;
        box-shadow: none;
        // min-height: calc(100vh - #{$top-bar-height-slim} - #{$template-toolbar-height});
        .contents {
          width: 100%;
        }
        h3 {
          font-size: 1.4rem;
          margin-bottom: 5px;
        }
        p {
          font-size: 1.4rem;
        }
      }

      .deal-panel-item {
        padding: 10px 15px;
      }

      hr {
        margin: 10px 0;
      }

      .section-sidebar {
        display: flex;
        flex-direction: column;
        flex: 1;

        .row-group {
          display: flex;
          flex-direction: row;
          width: 100%;
          margin-bottom: 5px;
        }

        .section-header-footer-config-item {
          background-color: white;
          border-bottom: 1px solid $black10;
        }

        .section-header-footer-config-editor {
          display: flex;
          padding: 10px 15px;
          flex-direction: row;
          flex-wrap: wrap;

          .row-group {
            .header-footer-item {
              &:not(.dropdown) {
                margin-left: 12px;
              }
              display: flex;
              flex-direction: column;
              flex: 2;
            }

            .pageCount {
              margin-left: 5px;
              flex: 1;
              .dropdown {
                ul.dropdown-menu {
                  min-width: 100px !important;
                }
              }
            }

            .font-size,
            .line-spacing {
              ul.dropdown-menu {
                min-width: 100px !important;
              }
            }

            .item-label {
              font-size: 1.3rem;
              color: rgb(51, 51, 51);
              font-weight: 500;
              // Bold the label too if item-label is a dmp Checkbox
              &.dmp-checkbox label {
                font-weight: 500;
              }

              a:hover {
                text-decoration: underline;
                cursor: pointer;
              }
            }

            .title {
              align-self: flex-end;
              font-weight: 600;
              color: rgb(51, 51, 51);
              flex: 2;

              span {
                height: 20.5px;
                .icon {
                  margin-left: 5px;

                  svg {
                    margin-top: -1px;
                    vertical-align: middle;
                    fill: $color-blue;
                  }
                }
              }
            }

            .font-size,
            .line-spacing {
              margin-left: 10px;
            }
          }
        }

        .section-style-item {
          background-color: white;
          border-bottom: 1px solid $black10;
        }

        .section-style-editor {
          display: flex;
          padding: 10px 15px;
          flex-direction: row;
          flex-wrap: wrap;

          .row-group {
            .style-item {
              display: flex;
              flex-direction: column;
              flex: 1;
            }
            .font,
            .color {
              flex: 2;
            }

            .font-size,
            .line-spacing {
              ul.dropdown-menu {
                min-width: 100px !important;
              }
            }

            .item-label {
              font-size: 1.3rem;
              color: rgb(51, 51, 51);
              font-weight: 500;
              // Bold the label too if item-label is a dmp Checkbox
              &.dmp-checkbox label {
                font-weight: 500;
              }

              a:hover {
                text-decoration: underline;
                cursor: pointer;
              }
            }

            .title {
              align-self: flex-end;
              font-weight: 600;
              color: rgb(51, 51, 51);
              flex: 2;

              span {
                height: 20.5px;
                .icon {
                  margin-left: 5px;

                  svg {
                    margin-top: -1px;
                    vertical-align: middle;
                    fill: $color-blue;
                  }
                }
              }
            }

            .font-size,
            .line-spacing {
              margin-left: 10px;
            }

            /*.color-picker {
              width: 100px;
            }*/

            .reset {
              display: flex;
              flex-direction: row;
              justify-content: right;
              .dmp-btn.dmp-btn-link.btn {
                width: 50px;
                padding: 0px;
              }
            }
          }
        }

        .form-group {
          position: relative;
          margin-bottom: 5px;
        }

        .section-error {
          .dmp-alert {
            line-height: 1.2;
            margin-bottom: 10px;
          }
        }

        .cl-search {
          .dmp-loader {
            position: absolute;
            right: 8px;
            top: 7px;
          }
        }

        .chk-index {
          margin-top: 10px;
        }

        .#{$dmp-switch} {
          margin-top: 2px;
          margin-bottom: 2px;
        }

        #chk-index-section-cl {
          margin-top: 1px;
        }

        .condition-editor {
          .condition-alert {
            margin-bottom: 10px;
          }
          .complex-switch {
            margin-top: 10px;
          }
          .conditional-group {
            margin-top: 10px;

            .condition-operator-selector {
              &.inline {
                background: $black2;
                margin-top: 0px;
                margin-bottom: 0px;
              }
              margin-top: 10px;
              margin-bottom: 10px;
              display: flex;
              justify-content: center;
              .condition-operator-and,
              .condition-operator-or {
                width: 50px;
                height: 30px;
              }

              .dotted-line {
                border-bottom: 1px dashed $gray-3;
                flex: 1;
                margin-bottom: 15px;
                margin-left: 10px;
                margin-right: 10px;
              }
            }
          }
          .condition {
            &.complex {
              margin-top: 0px;
            }
            margin-top: 10px;
            padding: 10px;
            position: relative;
            background: $black2;
            display: flex;
            align-items: flex-start;
            border-radius: 2px;
            border-left: 3px solid transparent;

            &.hasError {
              border-left: 3px solid $color-red;

              .condition-icon {
                path {
                  fill: $color-red;
                }
              }
            }

            .condition-icon {
              margin-right: 8px;
              path {
                fill: orange;
              }
            }

            .var-conditions {
              flex: 1;
              margin-top: -3px;

              .variable-options {
                margin-top: 2px;

                &.scrollable {
                  max-height: 200px;
                  overflow-y: auto;
                }

                .condition-option {
                  display: inline-block;
                  padding: 1px 3px;
                  border: 1px solid $black10;
                  background: $white;
                  font-size: 1.2rem;
                  margin-bottom: 5px;

                  label {
                    padding-left: 20px;
                  }

                  &:not(:last-child) {
                    margin-right: 8px;
                  }
                }
              }

              .values {
                &.pair {
                  display: flex;
                  align-items: center;
                  margin-bottom: 8px;

                  .and {
                    padding: 0 5px;
                  }
                }
              }

              .item-label {
                width: calc(100vw - 92px);
                overflow: hidden;
                text-overflow: ellipsis;

                @include mediaquery-medium {
                  width: 230px;
                }
              }
            }

            .remove {
              path {
                fill: $black20;
              }
              &:hover {
                path {
                  fill: $black80;
                }
              }
            }
          }

          .select-condition-operator {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .selection-label {
              align-self: center;
              font-size: 1.3rem;
              color: rgb(51, 51, 51);
              font-weight: 500;
            }
            .dd-condition-operator {
              button {
                min-width: 60px;
              }

              .dmp-dd-menu {
                min-width: 60px;
              }
            }
          }

          .new-condition {
            &.complex {
              margin-top: 0px;
            }
            margin-top: 10px;
            padding: 10px;
            background: $black2;
            .dropdown,
            .dropdown-toggle {
              width: 95%;
              margin-left: 5%;
            }
            .dropdown-menu {
              width: 100%;
            }

            .display-label {
              display: flex;
              svg {
                vertical-align: middle;
                margin-right: 5px;
                margin-left: 5px;
                height: inherit;

                path {
                  fill: $gray-2;
                }
              }
            }
          }

          .dmp-daytime-container-range {
            width: 100%;

            .DayPickerInput {
              flex-grow: 2;

              input {
                width: 100%;
              }
            }

            .InputFromTo-to {
              flex-grow: 2;

              input {
                width: 100%;
              }

              .DayPickerInput-Overlay {
                left: auto;
                right: 0;
              }
            }
          }
        }

        .page-style-container.indentation {
          .page-style-title {
            display: flex;
            justify-content: space-between;
          }

          &.active {
            .page-style-title {
              flex-direction: column;
            }
          }
        }
      }

      .cell-sidebar {
        .label-borders {
          margin-bottom: 5px;
        }
        .label-padding {
          margin-bottom: 5px;
        }
      }

      .elements-sidebar,
      .lens-sidebar {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        min-height: 0;

        .dmp-alert {
          margin-bottom: 0px;
        }

        .scoring {
          display: flex;
          flex-direction: column;
        }

        .enable-ai {
          flex-direction: column;
          height: auto;
          padding: 15px 15px !important;

          .dmp-alert {
            margin-bottom: 10px;
          }
        }

        .add-variable-lens {
          min-height: 90px !important;
        }

        .add-element,
        .add-lens {
          min-height: 50px;
        }

        .enable-ai,
        .add-element,
        .add-lens {
          border-bottom: 1px solid $black10;
          padding: 0 15px;
          display: flex;
          align-items: center;

          .lens-count,
          .element-count {
            font-size: 1.2rem;
            font-weight: 500;
            color: $black80;
            margin-right: 10px;
          }
          .element-unused {
            font-size: 1.2rem;
            color: $black30;
            display: flex;
            align-items: center;
            svg {
              margin: -2px 2px 0 0;
              path {
                fill: $black30;
              }
            }
          }

          .spacer {
            flex: 1;
          }
          .element-tip {
            margin-right: 5px;
            svg path {
              fill: $black40;
            }
          }

          .toggle-ai {
            width: 100%;
            display: flex;
            height: 15px;

            .extract-icon {
              display: flex;
              font-size: 12px;
              font-weight: 600;
              line-height: 15px;
              text-align: left;
              color: $gray-0;

              .dmp-i {
                vertical-align: middle;
                margin-right: 5px;
              }
            }

            .dmp-switch {
              margin-left: auto;

              button {
                vertical-align: top;
              }
            }
          }
        }
        .elements-list {
          .deal-panel-item {
            &.error {
              background-color: #fce8e7;
            }
            .element-topline {
              display: flex;
              align-items: center;
              flex-wrap: wrap;

              .wrap-element-text {
                flex: 1 1 75%;
                word-wrap: break-word;
                word-break: break-all;

                .ai-icon {
                  margin-right: 10px;
                  margin-top: -3px;
                  vertical-align: middle;

                  path {
                    fill: $gray-0;
                  }
                }
              }

              .template-variable {
                font-weight: 600;
                margin-right: 10px;
                cursor: pointer;
                min-height: 18px;
                line-height: 18px;
                display: inline;

                &.REF {
                  height: 20px;
                }
              }
              .element-type {
                color: $black40;
                display: inline;
              }

              .spacer {
                flex: 1;
              }

              svg {
                path {
                  fill: $black40;
                }
                &:hover {
                  path {
                    fill: $black80;
                  }
                }
                margin-left: 3px;
              }

              .popover-variable-editor {
                margin-top: 20px;
                word-break: break-all;
              }
            }

            .element-details {
              .list-values {
                font-size: 1.2rem;
                font-style: italic;
                color: $black80;
                margin-top: 2px;
              }
              .default-value {
                font-size: 1.2rem;
                font-style: italic;
                color: $black40;
                margin-top: 2px;
              }
            }
          }
        }

        // Decrease padding to make room for 5th tab (Connected vars)
        .panel-tabs {
          .dmp-btn-link {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }

      .list-sidebar {
        .party-selector,
        .dmp-checkbox:not(:last-child) {
          margin-bottom: 10px;
        }
        .number-formats {
          .property-row {
            margin-bottom: 5px;
          }
          .number-format-selector {
            width: 120px;
          }
        }

        // Override so that tooltips display properly
        .property-title {
          display: inline-block;
        }

        .list-REPEATER {
          .dmp-dd {
            margin-bottom: 10px;
          }
          textarea {
            min-height: 100px;
          }
        }

        .item-label.data-source {
          display: flex;
          span {
            flex: 1;
          }
          button {
            padding: 0;
          }
        }

        .chk-continuous {
          margin-bottom: 5px;
        }
        .chk-indent {
          margin-bottom: 5px;
        }
        .list-numbering {
          margin-top: 5px;
        }

        .ai-response-type {
          flex: 1;
        }
        .ai-output-variable {
          flex: 1;
          margin-left: 10px;
        }
        // VS is showing beneath a text area here so ensure that it's pushed down more so as not to obscure it
        .variable-suggest {
          top: 50px !important;
        }

        .ai-autorun,
        .ai-streaming {
          flex-wrap: wrap;
          align-items: end;
          margin-bottom: 2px;
          .spacer {
            flex: 1;
          }
        }
        .ai-prompt {
          min-height: 100px;
        }
        .help-spacer {
          height: 50px;
        }
      }

      .page-sidebar {
        .letterhead-behavior {
          label {
            font-weight: 500;
          }
          small,
          .letterhead-selector {
            padding-left: 32px;
          }

          //Switch align to the content
          #chk-show-title {
            margin-bottom: -1px;
          }
          margin-bottom: 5px;
        }
      }
    }
  }

  /*
  this is the nesting, nuts!
  .section-editor » .source » .header » .appendix » .signature
  > .section-text
  > > .DraftEditor-root
  > > > .public-DraftEditorPlaceholder-root
  > > > > .public-DraftEditorPlaceholder-inner
  > > > .DraftEditor-editorContainer
  > > > > .notranslate public-DraftEditor-content
  > > > > > .section-title | section-body
  > > > > > > .public-DraftStyleDefault-block
  */

  .section-columns {
    display: flex;
    .section-editor {
      max-width: 50%;
    }
    .public-DraftEditorPlaceholder-inner {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
    }
    .public-DraftEditorPlaceholder-inner > span {
      white-space: nowrap;
    }
  }

  .section-text {
    width: 100%;
  }

  .section-number + .section-text {
    // width: calc(100% - 32px);
  }

  //section editor is used on both Contract and Summary views
  .section-editor {
    position: relative;
    width: 100%;
    display: flex;

    &.page-break {
      @include pageBreak();
    }

    &.focused,
    &.model-error {
      z-index: 400;

      &:before {
        content: '';
        position: absolute;
        pointer-events: none;
        z-index: -1;
        top: -5px;
        bottom: -5px;
        left: -10px;
        right: -10px;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }

      &:not(.linked-cl, .isDefault) {
        &:before {
          background-color: rgba($white, 0.75);
          border: 1px solid $black3;
          box-shadow: 0 2px 3px 0 rgba($black100, 0.2);
        }
      }
      &:after {
        content: '';
        position: absolute;
        pointer-events: none;
        width: 3px;
        left: -10px;
        top: -5px;
        bottom: -5px;
        background: $outlaw;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }
      &.linked-cl {
        &:after {
          top: -5px;
          height: unset;
          bottom: -5px;
          left: -10px;
        }
      }
      &.model-error {
        &:not(.focused) {
          &:before {
            background-color: rgba($alert, 0.1);
            box-shadow: none;
            border: none;
          }
          &:after {
            background: none;
          }
        }
        &.focused {
          &:before {
            background-color: rgba($white, 0.75);
          }
          &:after {
            background: $alert;
          }
        }
      }
    }

    &.focused {
      &.align-center {
        .section-title {
          .public-DraftEditorPlaceholder-root {
            position: relative;
            display: block;
            z-index: -1;

            & + .DraftEditor-editorContainer {
              position: absolute;
              width: 100%;
              top: 0;
            }
          }
        }
      }
    }

    .section-body + .section-body {
      text-indent: initial;
    }

    &.isDefault,
    &.locked,
    &.linked-cl {
      z-index: 300;

      &:before {
        content: '';
        position: absolute;
        pointer-events: none;
        z-index: -1;
        top: -5px;
        bottom: -5px;
        left: -10px;
        right: -10px;
        background: $black5;
        border: 1px solid $black3;
        border-radius: 2px;
      }

      .locked-icon {
        position: absolute;
        right: -3px;
        top: 0;
        svg {
          width: 16px;
          height: 16px;
          path,
          rect {
            fill: $black100;
          }
          &:hover {
            path,
            rect {
              fill: $black90;
            }
          }
        }
      }

      .section-text > .section-body:first-child {
        padding-right: 20px; //Accommodating the lock icon
      }
    }

    &.linked-cl .locked-icon {
      transform: rotate(-45deg);
      cursor: pointer;
    }

    //headers, appendices and signature sections have centered titles
    &.appendix {
      @include centerPH();
    }

    &.signature {
      .section-text :first-child {
        @include centerPH();
      }
    }

    .cl-key-handler {
      opacity: 0;
      position: absolute;
      z-index: -100;
    }

    //used on Contract and Summary views
    .section-text {
      flex: 1;
      display: flex;
      flex-direction: column;

      .candidate-ref,
      .candidate-element {
        @extend .template-variable;
        background-color: rgba($entity-candidate-color, 0.25);
        border-color: rgba($entity-candidate-color, 0.75);
      }

      .var-suggest {
        @extend .template-variable;
        background-color: $black10;
        border-color: $black20;
      }

      .cl-lookup-text {
        font-style: italic;
        font-weight: 400;
      }
    }

    &.align-center {
      @include centerPH();
    }

    &.align-right {
      @include rightPH();
    }

    //source section is linked to active summary section
    .link-toggler,
    .linked-summary {
      position: absolute;
      left: -30px;
      top: 2px;
      padding: 0;
      font-size: 1.4rem;
      svg {
        path,
        rect {
          fill: $black40;
        }
      }

      &:hover:not(.linked-summary),
      &.linked-vital {
        svg path,
        svg rect {
          fill: $discuss;
        }
      }
      &.linked-summary {
        cursor: help;
      }
    }

    .activity-anchor {
      position: absolute;
      right: -45px;
      top: -4px;
      // top: auto;
      &.no-activity &:not(.showing) {
        display: none;
      }
      &.has-activity {
        display: block;
        svg path {
          fill: $discuss;
        }
      }
      &.showing {
        border-color: $black10;
        background-color: $white; //only show fill on desktop
        box-shadow: 0 2px 2px rgba($black100, 0.3);
        svg path {
          fill: $discuss;
        }
      }
      svg {
        top: 7px;
        left: 8px;
      }
    }

    &:hover {
      &.focused .activity-anchor {
        display: block;
      }
    }

    // Source section is linked to the currently selected summary
    &.linked {
      // actual button to toggle link
      .link-toggler {
        svg path,
        svg rect {
          fill: $discuss;
        }
      }
    }

    .section-number {
      padding-right: 0.5em;
      cursor: default;
    }

    // Source section is linked (but not to active summary section)
    &.linked-elsewhere {
      .section-number,
      .section-title,
      .section-body {
        color: $black40;
      }

      // actual button to toggle link
      .link-toggler {
        svg path,
        svg rect {
          fill: $black40;
        }
      }
    }

    // If we're in linking mode, we want to "dim" the custom display of variables
    // so that focus is on linking state
    &.linked,
    &.linked-elsewhere {
      .template-variable {
        background: none;
        color: inherit;
        text-decoration: none;
        border-color: $black10;
      }
    }

    .ugc-appendix,
    .ugc-note {
      margin-top: 20px;
    }
  }

  //placeholder basic styles
  .public-DraftEditorPlaceholder-root {
    position: absolute;
    display: inline;
    width: 100%;
    z-index: 299;
    pointer-events: none;
    color: $black20;
  }

  .section-editor {
    .appendix-end,
    .signature {
      margin-bottom: 46px;
      &:after {
        content: '';
        position: absolute;
        left: -16px;
        right: -17px;
        bottom: -26px;
        background: $black10;
        height: 1px;
      }
    }
  }

  .appendix {
    flex-direction: column;

    .section-text {
      text-align: center;
    }
  }

  .list {
    .ugc-list {
      padding: 5px 0;
      &.indented {
        padding-left: 32px;
      }
      .dmp-alert {
        margin-bottom: 0;
        .dmp-alert-content {
          align-items: center;
          display: flex;
          strong {
            margin-right: 5px;
          }
          svg {
            margin-right: 5px;
            path {
              fill: $discuss;
            }
          }
        }
      }
    }

    &.overview-mode {
      align-items: center;
      .link-toggler {
        top: auto;
      }
    }
  }

  .signature {
    flex-direction: column;

    .DraftEditor-root {
      &:last-child {
        .public-DraftEditorPlaceholder-inner {
          text-align: left;
        }
      }
    }

    .ugc-signature {
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      bottom: 12px;

      .sig-block {
        flex-basis: 48%;
        margin-bottom: 20px;
        &:nth-child(odd) {
          margin-right: 2%;
        }
        &:nth-child(even) {
          margin-left: 2%;
        }
        position: relative;
        padding: 30px 20px;

        // sig-block will be missing if it was explicitly part of the signature section (signatories)
        // but then the party gets deleted from the template
        &.missing {
          border-color: rgba($alert, 0.5);
        }
      }

      .party-name {
        display: block;
        margin-bottom: 15px;
      }
      .party-actions {
        margin-top: 15px;
      }

      button.remove {
        @extend .circle-x;
      }

      .#{$dmp-switch} {
        width: 100%;
      }
    }
    .add-signatories {
      button {
        display: inline-block;
        color: $outlaw;
      }
    }
  }

  .popover-content {
    .field.id {
      //make the variable field look like a title
      margin-bottom: 15px;
      pointer-events: none;
    }
  }
}

.template-toolbar {
  position: fixed;
  display: flex;
  padding: 0 10px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: $template-toolbar-height;
  width: 100%;
  background: $white;
  border-bottom: 1px solid $black10;
  z-index: $layer-fixedbars;
  left: 0;
  top: 0;
  visibility: hidden; //hide on mobile

  @include mediaquery-medium {
    visibility: visible;
    top: $top-bar-height-slim;
  }

  .toolbar-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    height: 30px;
    padding-left: 5px;
    padding-right: 5px;
    border-right: 1px solid $black10;

    // TODO: remove this once fixed in dmp component
    // just putting in here to confirm enabled/disabled state logic is working correctly
    .dmp-btn-icon-disabled {
      opacity: 0.4 !important;
      cursor: not-allowed !important;
    }

    .dropdown-toggle {
      text-decoration: none;
      svg:first-child {
        width: 15px;
        height: 15px;
      }
    }

    .add-section {
      svg {
        margin-right: 0;
      }
    }
    .insert ul {
      max-height: inherit;
    }

    .appendix-flavor {
      ul {
        min-width: 170px;
      }
    }

    &.trash {
      border-right: none;
    }

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border-right: 0;
      button {
        margin-left: 5px;
      }
    }
  }

  .spacer {
    flex: 1;
  }

  // These are mimicing the panel icons in DealHeader
  // But are disabled in Draft mode
  .flow-buttons {
    padding-right: 10px;
    button {
      border: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 40px;
      position: relative;
      &:hover,
      &:active {
        background: none;
        border: none;
      }
    }
  }
}

.variable-suggest {
  background: $white;
  border: 1px solid $black10;
  border-radius: 2px;
  box-shadow: 0 2px 3px 0 rgba($black100, 0.2);
  padding: 3px 0;
  min-width: 300px;
  text-align: left;
  z-index: 10; //ensure this shows above Notify/Redline checkboxes in Flow

  .options {
    max-height: 300px;
    max-width: 300px;
    overflow-y: scroll;

    .option {
      @extend .font_sans;
      padding: 5px 10px;
      cursor: pointer;
      position: relative;

      &:not(:last-child) {
        border-bottom: 1px solid $black10;
      }

      &.option-icon {
        padding-right: 40px;
      }

      &.active {
        background: $gray-5;
      }

      &.el-type {
        display: flex;
        align-items: center;

        .el-title {
          @extend .font_size_s;
          flex: 1;
          margin-left: 10px;
          font-weight: 700;
        }
        .el-char {
          color: $black40;
        }
      }

      &.option-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $gray-2;
        font-size: 12px;
        padding: 8px 10px;
      }

      &.vs-instructions {
        @extend .font_size_xxs;
        padding-bottom: 12px;
        padding-top: 10px;
      }

      &.add-new {
        @extend .font_size_s;
        text-decoration: underline;
      }

      &.disabled {
        cursor: not-allowed;
        background: none;
        color: $black40;
        text-decoration: none;
      }

      &.var-select {
        .var-topline {
          display: flex;
          align-items: center;

          .var-name {
            @extend .font_size_s;
            font-weight: 600;
            margin-right: 5px;
            overflow: hidden;
          }
          .var-type {
            @extend .font_size_s;
            color: $black40;
          }
        }

        .var-display-name {
          @extend .font_size_s;
          color: $black40;
          font-style: italic;
        }
      }

      .connect-type {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0.5;
      }
    }
  }
  .footnote {
    @extend .font_sans;
    padding: 20px;
    cursor: pointer;
    overflow-y: hidden;

    .header {
      font-weight: 600;
      font-size: 1.4rem;
      color: $black80;
      cursor: text;
    }

    .footnote-selector {
      margin-bottom: 10px;
      width: 240px;
      position: absolute;

      .footnote-options {
        width: 240px;
      }
    }

    .variable-value {
      max-width: 240px;
      max-height: 80px;
    }

    .footnote-dd-container:after {
      content: ' ';
      display: block;
      height: 30px;
    }

    .footnote-config-info {
      display: inline-block;
      svg {
        vertical-align: middle;
        margin-top: -2px;
      }
    }

    .radio {
      font-size: 14px;
      input {
        margin-top: 0;
      }
      label {
        display: flex;
        align-items: center;
      }
    }

    .body,
    .header {
      margin-bottom: 12px;
    }
    .footer {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .right {
        margin-left: 5px;
      }
      .left {
        margin-right: auto;
      }
    }
  }
}

.section-suggest {
  background: none;
  z-index: 1;

  .cl-searching {
    display: inline-flex;
    gap: 5px;
    .dmp-loader {
      align-items: center;
    }
  }

  .add-cl-button {
    color: $black30;
    font-weight: 600;
    text-decoration: underline;
  }

  .options {
    background-color: #252f3d;
    .option {
      display: flex;
      padding: 10px 15px;
      border-bottom: 1px solid lighten(#252f3d, 8%);
      cursor: pointer;
      @extend .font_size_xs;

      &.clause-select {
        @extend .font_sans;
        align-items: center;

        &.active {
          background-color: lighten(#252f3d, 8%);
        }

        .clause-title {
          flex: 1;
          color: $white;
          text-transform: uppercase;
          font-weight: 600;
          padding-right: 5px;
        }
        // Matched highlights from search render as <em> tags
        em {
          font-style: normal;
          color: $discuss;
        }

        .statusLabel {
          min-width: 18px;
        }
      }

      &.section-select {
        .section-full {
          flex: 1;
        }
        .title {
          @extend .font_serif_app;
          color: $white;
          font-weight: 500;
        }

        .section-preview {
          @extend .font_serif_app;
          font-size: 1.1rem;
          color: $white;
          max-height: 80px;
          overflow: hidden;
          white-space: normal;
          text-overflow: ellipsis;
          line-height: 1.5;
        }
      }
      &.active {
        background-color: #252f3d;
        .section-preview {
          overflow: visible;
          max-height: inherit;
        }
      }

      &.disabled {
        cursor: not-allowed;
        background: none;
        color: $black40;
        text-decoration: none;
      }
    }
  }

  .cl-meta {
    display: flex;
    background-color: #252f3d;
    font-size: 1.1rem;
    @extend .font_sans;
    color: $black40;
    padding: 5px 15px;

    .lhs,
    .rhs {
      flex: 1;
      align-items: center;
    }

    .template-link a {
      font-size: 1.1rem;
    }

    .cycle {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: $white;
      height: 100%;

      .current-section {
        padding: 1px 5px 0;
      }
    }

    .conditions-view {
      svg path {
        fill: $black40;
      }
      color: $black40;
    }
  }
  .kb {
    font-size: 1.1rem;
    @extend .font_sans;
    color: $black40;
    display: flex;
    justify-content: flex-end;
    .instruction {
      &:not(:last-child) {
        margin-right: 10px;
      }
      span {
        color: $black30;
        font-weight: 700;
      }
    }
    &.separate {
      padding: 5px 15px;
      background-color: #252f3d;
      border-top: 1px solid lighten(#252f3d, 8%);
      border-bottom: 1px solid lighten(#252f3d, 8%);
    }
  }

  &:not(.contextual) .cl-meta .rhs {
    flex: unset;
  }

  &.contextual {
    position: absolute;
    top: 23px;
    left: -10px;
    right: -10px;
    border-radius: 3px;
    box-shadow: 0 2px 3px 0 rgba($black100, 0.2);

    .options {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;

      .option {
        &:first-child {
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
        }
      }
    }

    .source-template a {
      text-decoration: underline;
    }

    .cl-meta {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      &.no-results {
        border-radius: 3px;
      }
      .cycle {
        height: auto;
      }
    }
  }
}

.popover-variable-filter {
  max-width: 450px;
  width: 450px;

  .existing {
    border-color: rgba($entity-var-color, 0.25);
  }

  .variable-options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    opacity: 0.5;

    // overrides to get it looking vertically centered and appearing inside
    .#{$dmp-checkbox} {
      border: 1px solid $black10;
      background-color: $white;
      border-radius: 2px;
      padding: 1px 5px;
      margin-top: 2px;
      margin-bottom: 2px;
      &:not(:last-child) {
        margin-right: 5px;
      }
      label::before {
        padding-top: 5px;
      }
      label::after {
        padding-top: 3px;
      }
    }
  }
}

//logo uploader styling
.logo-uploader-inner {
  position: relative;

  .remove-logo {
    position: absolute;
    width: 21px;
    height: 21px;
    line-height: 20px;
    font-size: 19px;
    background: $black80;
    border-radius: 50%;
    border: 1px solid $white;
    top: 4px;
    left: 4px;
    color: $white;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    @extend .font_sans;
  }

  .preview {
    border: 1px dashed $black20;
    padding: 0.25rem;
    border-radius: 3px;
    background-color: $black2;
    display: flex;

    img {
      //logo inherits size of outer div
      padding: 5px;
      max-width: 100%;
      max-height: 100%;
      margin: auto;
    }
  }

  .upload {
    cursor: pointer;
    display: flex;
    .hit-area {
      width: 100px;
      height: 100px;
      margin-right: 20px;
      background-color: $white;
      border: 1px dashed $black20;
      background-image: url(/assets/svg/icon-logo-upload.svg);
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 3px;
      &:hover {
        background-color: $white;
        border-color: $black30;
      }
    }
  }
}

.template-info {
  $height-of-teammate-and-roles-desktop: 300px;
  .tab-users {
    //stacked vertically on mobile, then left/right on desktop
    display: flex;
    flex-direction: column;
    @include mediaquery-medium {
      flex-direction: row;
    }
    width: 100%;
    padding-bottom: 20px;
    position: relative;

    //teammate selector is on the left hand side
    .teammate-selector {
      height: 260px;
      margin-bottom: 10px; //give some space between this and .default roles

      @include mediaquery-medium {
        margin-bottom: 0;
        flex-basis: 200px;
        height: $height-of-teammate-and-roles-desktop;
      }

      .teammates-list {
        height: calc(100% - 16px);
      }
    }

    //default roles is on the right side
    .default-roles {
      height: 260px;
      flex-basis: 260px;
      background-color: $black2;
      border: 1px solid $black10;
      border-radius: 1px;
      flex: 1;
      overflow: hidden;

      @include mediaquery-medium {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
        height: $height-of-teammate-and-roles-desktop;
      }

      .col-headers {
        background: $white;
        height: 30px;
        display: flex;
        flex-direction: row;
        padding: 0 40px 0 10px;
        align-items: center;
        border-bottom: 1px solid $black10;
        justify-content: space-between;
        label {
          margin-bottom: 0;
          font-size: 1.2rem;
          font-weight: 600;
          color: $black40;
        }
        &:first-child {
          flex: 1;
        }
      }
      .du-list {
        height: calc(100% - 32px);
        overflow-y: scroll;

        .deal-panel-item {
          padding: 15px;
        }
      }
    }
  }

  .modal-body .tab-connections {
    padding-bottom: 10px;

    .connection {
      .header {
        vertical-align: center;
        .dmp-i {
          width: 20px;
          height: 20px;
          vertical-align: middle;
          margin-right: 10px;
        }
      }

      .dmp-alert-danger {
        margin-top: 10px;
      }

      &-content {
        padding-left: 30px;

        .form-group {
          flex-direction: column;
          margin-bottom: 4px;

          .control-label {
            font-size: 1.2rem;
            color: $gray-1;
            font-weight: 600;
            flex-basis: initial;
          }
        }
      }
    }
  }

  .url-thing {
    color: $black40;
  }
  small {
    display: block;
  }
  .template-area {
    margin: 10px 0;
  }

  .warning {
    color: $alert;
    margin-top: 5px;
    font-weight: 700;
  }
}

//reusable items
.informative {
  // text style used on user-generated sections during authoring

  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: $black1;
  border: 1px dashed $black10;
  border-radius: 6px;
  @extend .font_sans;
  color: $black40 !important;
  .instructions {
    font-size: 1.2rem;
    white-space: normal;
    cursor: auto;
    @extend .font_sans;
    color: $black40 !important;
  }
}
