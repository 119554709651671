html {
}

body {
  padding: 0;
  margin: 0;
  font-weight: 400;
  color: $black80;
  background: $black2;
  -webkit-font-smoothing: antialiased;

  $connect-button-height: 50px;

  .connect-button {
    //google, microsoft buttons used on
    &:focus {
      outline: none !important;
    }
    /* Rectangle 82: */
    display: block;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    background: $white;
    border: 1px solid $black10;
    box-shadow: inset 0 -2px 0 0 rgba($black100, 0.1);
    border-radius: 3px;
    position: relative;
    height: $connect-button-height;
    text-align: left;
    font-weight: 700;
    font-size: 1.3rem;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    span {
      padding-left: 20px;
      display: block;
      color: $outlaw;
    }
    svg {
      position: absolute;
      right: 15px;
      top: 14px;
      width: 16px;
      path {
        fill: $outlaw;
      }
    }
    // &.guestlogin svg path { fill: $black40; }
    &:hover {
      background-color: darken($white, 1%);
      border-color: $black20;
    }
    &:active {
      box-shadow: inset 0 2px 0 0 rgba($black100, 0.1);
      background-color: darken($white, 2%);
      border-color: $black40;
      img {
        top: 15px;
      }
      span {
        padding-top: 1px;
      }
    }
  }

  //reusable form styles
  .field-block {
    border-bottom: 1px solid $black10;
    padding: 20px;
    // margin-bottom: 20px;
    &:last-child {
      border-bottom: 0;
    }
    h2 {
      display: block;
      padding-bottom: 20px;
    }
    .wrapper {
      width: 100%;
      @include mediaquery-medium {
        display: table;
      }
    }
    .prompt {
      width: 100%;
      color: $black40;
      line-height: 1.8;
      font-weight: 300;
      margin-bottom: 16px;

      @include mediaquery-medium {
        display: table-cell;
        vertical-align: top;
        width: $prompt-width;
        min-width: $prompt-width;
        padding-right: 24px;
      }
    }
    .fields {
      width: 100%;
      // min-width: 300px;
      @include mediaquery-medium {
        min-width: 360px;
        display: table-cell;
        width: 70%;
        padding-left: 30px;
      }

      input {
        //override bootstrap padding
        padding: 6px 8px;
      }
      button {
        float: right;
        margin-top: 5px;
      }
      .help-block {
        display: inline-block;
        margin-top: 5px;
        font-size: 1.3rem;
      }
    }
  }
}

.text-right {
  text-align: right;
}
.no-visibility {
  visibility: hidden;
}
//styling
.bold {
  font-weight: 600;
}
.underline {
  text-decoration: underline;
}
.italic {
  font-style: italic;
}
.flex-end {
  align-self: flex-end;
}
// lighter text color, gotta get some of that contrast
.lighter {
  color: $black40;
}
.center {
  text-align: center;
}

.margin-top-s {
  margin-top: 10px;
}
.margin-bottom-s {
  margin-bottom: 10px;
}
.margin-right-s {
  margin-right: 20px;
}

.float-left {
  float: left;
}

//New style for all forms
.form-group:not(.form-group-vertical) {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .control-label {
    @extend .txt_body;
    font-weight: 700;
    font-size: 1.4rem;
    color: $black80;
    display: block;
    flex-basis: 125px;
    padding-top: 6px;
  }

  &.complex {
    .control-label {
      flex-basis: unset;
    }
  }

  .contents {
    flex: 1;
    max-width: 375px;
    button {
      //dropdowns in modals
      float: none;
      //toggle buttons (e.g., Inbound)
      &.btn-xs {
        margin: 4px 0;
      }
    }
    small {
      display: block;
      padding-top: 2px;
    }

    .toggle-verbose:not(:last-child) {
      .letterhead-selector {
        padding-left: 32px;
      }
      margin-bottom: 10px;
    }
  }
  .form-control {
    flex: 1;
  }
  .form-control-with-dropdown {
    flex: 3;
    margin-right: 10px;
  }

  .address-properties {
    display: flex;
    :not(:last-child) {
      margin-right: 10px;
    }
  }
}

:focus::-webkit-input-placeholder {
  opacity: 0.5;
}

.bigshadow {
  @include mediaquery-medium {
    box-shadow: 0 0px 1px rgba($black100, 0.2), 0 5px 30px rgba($black40, 0.2); //page shadow
  }
}

// Alignment
.grid {
  display: grid;
  grid-auto-flow: column;
  gap: 1em;
}

.equal-grid {
  @extend .grid;

  grid-auto-columns: 1fr;
}
